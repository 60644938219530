.session-layout {
margin-top: 10px;
}

.session-content{

display: flex;
justify-content: center;

}

.receipts-title{
position: sticky;
width: 343px;
height: 32px;
left: 16px;
top: calc(50% - 32px/2 - 239px);
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
color: rgba(0, 0, 0, 0.87);
}

.session-list{
text-align: center;
height: 50vh;
}

.list-layout{
position: relative;
background: #FFFFFF;
box-shadow: 0px 1px 5px rgb(0 0 0 / 20%), 0px 6px 16px rgb(0 0 0 / 6%);
border-radius: 8px;
margin:15px
}
.icon-layout{
display: flex;
flex-direction: row;
align-items: right;
padding: 8px;
position: static;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;
background: rgba(0, 0, 0, 0.06);
border-radius: 32px;
/* flex: none; */
order: 0;
flex-grow: 0;
margin: 0px 0px;
}

.icon-layout-pending{
    display: flex;
    padding: 8px;
    font-size: 20px;
}
.payment-required-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-required{
    margin-left: 1px;
    font-weight:800;
    color: red;
    font-size: 10px;
}

.session-period{
    padding: 0 0 0 1.2em;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #484848;
    line-height: 1em;
}

.session-option{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
height: 48px;
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.06);
box-sizing: border-box;
border-radius: 8px;
margin: 15px;


}

.product-image{
position: inherit;
width: 40px;
height: 40px;
left: 16px;
top: 19px;
background: #FFFFFF;
border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 5px

}

.product-date{

    display:flex;
    align-items:center;
    font-style: normal;
    font-weight: normal;
    font-size: 12pt;
    letter-spacing: 0.4pt;
    color: #8C8C8C;

}
.product-payment{
    display:flex;
    align-items:center;
}
.left-arrow{
    display:flex;
    align-items:center;
}
.product-amount{
display:flex;
align-items:center;
font-style: normal;
font-weight: 600;
font-size: 16px;
/* line-height: 32px; */
text-align: right;
color: #439E4A;

}
.card-type{
display:flex;
align-items:center;
font-style: normal;
font-weight: normal;
font-size: 12px;
/* line-height: 42px; */
text-align: right;
letter-spacing: 0.4px;
color: #919191;
}


.no-data{
background: #ffffff;
text-align: center;
margin-top: 20px;
margin-bottom: 20px;
}
.no-data-image{
background: #ffffff;
width:auto;
height:200px
}
