

.billing-title{
position: flex;
width: 327px;
left: 25px;
top: calc(50% - 32px/2 - 239px);
font-style: normal;
font-weight: 600;
font-size: 24px;
}
.billing-fields{
    height: 500px;
    display: flex;
  flex-direction: column;

}

.parent-container {
position: relative;
}

.grid-container {
width: 100%;
height: 50%;
overflow: visible;
margin: auto;
top: 0; left: 0; bottom: 0; right: 0;
}
/* Align checkbox on the left */
.invoice-class {
    --padding-start: 0px !important;
}

 

