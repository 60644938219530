
.success-toast{
--ion-color-base:#E5FFE7 !important;
--box-shadow:0;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 16px 16px 24px;
height: 80px;
color:#006907;
background: #E5FFE7;
box-shadow: inset 6px 0px 0px #006907;
border-radius: 8px;
margin-left: 5px;
margin-top: 5px;
}

.danger-toast{
--ion-color-base:#FFE5E5 !important;
--box-shadow:0;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 16px 16px 24px;
height: 80px;
color:#FA0000;
background: #FFE5E5;
box-shadow: inset 6px 0px 0px #FA0000;
border-radius: 5px;
margin-left: 5px;
margin-top: 5px;

}

.warning-toast{
--ion-color-base:#FFF9E5 !important;
--box-shadow:0;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 16px 16px 16px 24px;
height: 80px;
color:#876700;
background: #FFF9E5;
box-shadow: inset 6px 0px 0px #876700;
border-radius: 8px;
margin-left: 5px;
margin-top: 5px;
}