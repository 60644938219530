ion-slides {
height: 100% !important;
}

.slide-grid {
height: 100%;
}

.slide-title {
position: flex;
width: 100%;
font-style: bold;
font-weight: 800;
font-size: 25px;
line-height: 35px;
text-align: center;
color: rgba(0, 0, 0, 0.87);
}

.slide-subtitle {
position: absolute;
width: 325px;
height: 65px;
left: 24px;
top: calc(50% - 65px / 2 - 254.5px);
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 32px;
text-align: center;
color: rgba(0, 0, 0, 0.87);
}

.parent-container {
position: relative;
}
.grid-container {
width: 100%;
height: 50%;
overflow: visible;
margin: auto;
top: 0; left: 0; bottom: 0; right: 0;
}

.main-logo{
position:absolute;
overflow:visible;
display: block;
flex-direction: row;
justify-content: center;
width: 100%;
align-items: center;
align-content: center; 
top: 0; left: 0; bottom: 0; right: 0;
padding:2rem

}



.slide-other-images{
left: 50%;
right: 50%;
display: block;
flex-direction: row;
justify-content: center;
width: 100%;
align-items: center;
align-content: center; 



}
.slide-image-layout{
width: 100%;
height: 42vh;
padding-top: -2rem;
display: block;
flex-direction: row;
justify-content: center;
width: 100%;
align-items: center;
align-content: center; 
top: 0; left: 0; bottom: 0; right: 0;
margin-top: 2rem;


}

.onboarding-button {

width: 100%;
align-items: center;
align-content: center; 
justify-content: center;
top: 0; left: 0; bottom: 0; right: 0;
margin-top: 2rem;
}
.row-layout {
width: 100%;
align-items: center;
align-content: center; 
justify-content: center;
top: 0; left: 0; bottom: 0; right: 0;
margin-top: 10px;
}

.slide-buttons {
position: absolute;
bottom: 2rem;
z-index: 10;

display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
align-items: center;
align-content: center;
}

.slide-content-container {
margin-top: -4rem;
}

.slide-content {
margin: 0 auto;
color: var(--ion-color-primary);
border-radius: 15px;
}

.slide-content p {
color: rgb(161, 161, 161);
}

.onboarding-button-group {
position: absolute;
height: 152px;
left: 0px;
top: 27em;
background: #ffffff;
}


