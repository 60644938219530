.container {
    text-align: left;
    font-weight: bold;
}


.store-image{
   width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgb(15, 15, 15);
}


 