ion-router-link {
    position: relative;
    z-index: 10;
}

.no-left-padding {
    margin-inline-start: 5px !important;
}

.form_group_recaptcha>div>div {
    display: flex;
    place-content: center;
}

.hint {
    color: #555;
}

.toast-truce {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: -15px 16px 16px 24px;
    position: absolute;
    width: 440px;
    height: 85px;
    left: 24px;
    top: 220px;
    background: #FFE5E5;
    box-shadow: inset 6px 0px 0px #fa0000;
    border-radius: 8px;
}

.birthday-field {
    width: 100%;
}

.birthday-card {
    width: 100%;
    padding: 11.4px 12px;
    background: rgba(218, 218, 218, 0.8);
    border-radius: 5px;
    margin-bottom: 1rem;
    border: 0;
    line-height: 25px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.025em;
}


.plt-android ion-input[type="date"]:not(.has-value)>input:before,
.plt-ios ion-input[type="date"]:not(.has-value)>input:before {
    content: attr(placeholder);
    color: rgba(66, 71, 112, 0.5);
}

ion-input[type="date"] {
    height: 45px;
}