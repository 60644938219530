.redColor {
    color: red;
    font-weight: bold;
}

.greenColor {
    color: green;
    font-weight: bold;
}

ion-button {
    text-transform: none;
}
