.left-field {
    width: 100%;
    margin-right: 4px;
}
.right-field {
    width: 100%;
}
.card-name {
    width: 100%;
    padding: 11.4px 12px;
    background: rgba(218, 218, 218, 0.8);
    border-radius: 5px;
    margin-bottom: 1rem;
    border: 0;
    line-height: 25px;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.025em;
    color: #424770;
}
 
.background-white{
    background: #ffffff;
}
