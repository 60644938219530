.quantity {
    padding-right: 5px;
    color: #aaa;
}

.list-footer, .line-top {
    border-top: 1px solid #ddd;
    background-color: white;
}

.itemSlotEnd {
    flex-grow: 0.5;
}

.no-shadow {
    box-shadow: none;
}

.item-image image {
    max-height: 130px;
}

.item-dialog .popover-content {
    flex: center;
}

.payment-button {
    margin: 20px;
}

.subtotal-text{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #484848;
    width: 65px;
    height: 16px;
    text-align: left;
}
.subtotal-number{
    width: 61px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: #008A09;
}
      
