.top-toolbar {
    --min-height: 100px;
    overflow: visible;
}

.avatar {
    width: 30px;
    height: 30px;
}

.avatar-image {
    width: 30px;
    height: 30px;
}

.top-logo {
    margin-left: 5px;
    height: 65px;
    width: auto;
}

.top-buttons {
    min-height: 65px;
}