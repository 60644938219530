ion-spinner {
    display: block;
    margin: auto;
}

.payment-page-logo {
    position: flex;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
}
.add-card-title {
    position: flex;
    width: 327px;
    height: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.87);
}

.add-card-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: absolute;
    left: 6.4%;
    right: 6.13%;
    top: 82.61%;
    bottom: 10.87%;
    background: #ff6000;
    border-radius: 8px;
}
.add-card-button-group {
    position: absolute;
    height: 152px;
    left: 0px;
    top: 27em;
    background: #ffffff;
}
.card-element {
    width: 100%;
    padding: 11.4px 12px;
    background: rgba(218, 218, 218, 0.8);
    border-radius: 5px;
    margin-bottom: 1rem;
    border: 0;
    line-height: 25px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.025em;
    color: #424770;
}
.card-focus {
    width: 100%;
    padding: 11.4px 12px;
    background: rgba(218, 218, 218, 0.8);
    border-radius: 5px;
    margin-bottom: 1rem;
    border: 0;
    line-height: 25px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.025em;
    border: 2px solid #ff6000 !important;
    box-sizing: border-box;
}
.card-error {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffe5e5;
    border: 2px solid #fa0000;
    box-sizing: border-box;
    border-radius: 8px;
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.left-field {
    width: 70%;
    margin-right: 4px;
}
.right-field {
    width: 70%;
}
.card-name {
    width: 100%;
    padding: 11.4px 12px;
    background: rgba(218, 218, 218, 0.8);
    border-radius: 5px;
    margin-bottom: 1rem;
    border: 0;
    line-height: 25px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.025em;
    color: #424770;
}
