ion-slides {
  height: 40%;
}

.user-row {
  margin-bottom: 30px;
}

.dashboard-img {
  height: 12em;
}

.backModal {
  position: absolute;
  border: 1px solid #eee;
  border-radius: 5px;
  width: 80%;
  left: 10%;
  top: 40%;
  min-height: 100px;
  box-shadow: 5px 5px 5px #eee;
}

.backToolbar {
  padding-left: 5px;
  padding-right: 5px;
}