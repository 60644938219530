.account-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: initial;
    top: 90.22%;
    bottom: 3.26%;
    border-radius: 5px;

}

.tab-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: sticky;
    width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 6px 16px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    flex: none;
    order: 0;
    margin: 1px 0px;
}

.header-logo {
    position: flex;
    left: auto;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
    margin-bottom: 2rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-height: 250px;
    width: 80%;
    max-width: 250px;
}