.content-layout {
  text-align: center;
}

.payment-status{
  text-align: center;
    display: block;
    width: 100px;
    
}
.payment-logo{
    align-items: center;
    justify-content: center;
    height: 100%;
}

.payment-name{
height: 32px;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
text-align: center;
color: rgba(0, 0, 0, 0.87);
}
.payment-amount{
height: 40px;
left: 140px;
right: 140px;
top: 312px;
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 40px;
text-align: center;
color: #5BD464;

}
.payment-charge{
  font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #212121;
}