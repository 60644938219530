

.profile-avatar{
    text-align: center;
}
.profile-title{
    text-align: center;
}
.profile-list{
    text-align: center;
    height: 50vh;
}

.parent-container {
    position: relative;
}
.title-layout{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
}
.grid-container {
    width: 100%;
    height: 50%;
    overflow: visible;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
}


.profile-logo{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    left: 36.8%;
    right: 36.53%;
    top: 13.32%;
    bottom: 73.1%;
    border: 1px solid rgba(0, 0, 0, 0.06);
}
 
.row-layout {
    width: 100%;
    align-items: center;
    align-content: center; 
    justify-content: center;
    top: 0; left: 0; bottom: 0; right: 0;
    margin-top: 10px;
}


