.receipt-row {
    margin: 5pt 0;
}


.product-name {
    font-style: normal;
    font-weight: 600;
    font-size: 12pt;
    color: #484848;
}

.product-price {
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    color: #439E4A;
    text-align: right;
}

.product-description {
    height: 24px;
    left: 0%;
    right: 0%;
    top: calc(50% - 24px/2 - 9.5px);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #484848;
}

.product-quantity {
    height: 24px;
    left: 0%;
    right: 0%;
    top: calc(50% - 24px/2 - 9.5px);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #484848;
}

.billing-information {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #484848;
}

.billing-details-taxCode {
    padding: 0 1.8em 0 0;
}

.billing-details {
    font-style: normal;
    font-weight: normal;
    font-size: 12pt;
    line-height: 22pt;
    letter-spacing: 0.3pt;
    color: #484848;

}

.payment-information {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #484848;

}

.payment-details {
    padding: 0.5em 0;
    color: #484848;
    border-radius: 4px;
}

.row-total {
    /* border-top: 2pt solid #EFEFEF */
}

.total-pay {
    margin: 0 0 0 1.2em;
    font-style: normal;
    font-weight: bold;
    font-size: 14pt;
    line-height: 24pt;
    letter-spacing: 0.4px;
    color: #484848;
}

.total-amount {
    margin: 0 1.2em 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18pt;
    line-height: 24pt;
    text-align: right;
    color: #008A09;
}