.list .item.item-accordion {
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  transition: 0.09s all linear;
}

.list .item.item-accordion.ng-hide {
  line-height: 0px;
}

.list .item.item-accordion.ng-hide-add,
.list .item.item-accordion.ng-hide-remove {
  display: block !important;
}

.item.item-accordion a {
  padding-left: 5px;
  padding-right: 5px;
}

.show {
  display: block
}

.hide {
  display: none;
}

.item-accordion {
  text-align: justify;
  font-weight: 500;
}

.item-stable {
  text-align: justify;
  font-weight: 900;
}