.GeoMap {
    width: 100%;
    position: fixed;
    height: 100%;
}

.map-container {
  width: 100%;
  height: 100%;
}

.map-ref {
  height: 100%;
}

.btn {
  font-size: 30px;
}
