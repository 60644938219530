
.card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.left-field {
    width: 70%;
    margin-right: 4px;
}
.right-field {
    width: 70%;
}
.sc-ion-input-md-h {
    width: 100%;
    padding: 11.4px 12px;
    background: rgba(218, 218, 218, 0.8) !important;
    border-radius: 5px;
    margin-bottom: 1rem;
    border: 0;
    line-height: 25px;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.025em;
    color: #424770;
}
.verify-email-title{
    position: flex;
    width: 327px;
    height: 32px;
    top: calc(50% - 32px/2 - 132px);
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.87);
}
.verify-email-subtitle{
    position: flex;
    height: 70px;
    top: calc(50% - 70px/2 - 72px);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: rgba(0, 0, 0, 0.87);
}
