

.payment-title{
position: flex;
width: 327px;
height: 32px;
left: 25px;
top: calc(50% - 32px/2 - 239px);
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;

}
.payment-list{
text-align: center;
height: 50vh;
}

.parent-container {
position: relative;
}
.title-layout{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
left: 0px;
top: 0px;
background: #FFFFFF;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.grid-container {
width: 100%;
height: 50%;
overflow: visible;
margin: auto;
top: 0; left: 0; bottom: 0; right: 0;
}



