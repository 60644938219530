.tap-item:active{
    --background:#eee;
}

.add-remove-slot{
    border-radius: 30px;
    border: 1px solid #eee;
    height:32px;
    width:auto;
    padding-right:1px;
}

.add-remove-btn{
  padding: 0;
  margin: 0;
  width:30px;
  height:30px;
  font-size:1.1rem;
}

.productThumb {
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 5px;
}

.fit-contain{
    object-fit: contain !important;
}
